/* eslint-disable  */
// import Chart from "./Components/Chart";
import { Badge, Divider, IconButton } from "@material-ui/core";
import { MessageSharp, NotificationImportant } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { AiFillGold } from "react-icons/ai";
import { BiBookAlt } from "react-icons/bi";
import { BsCalculator, BsMenuButtonFill } from "react-icons/bs";
import { FaHands } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { GiMoneyStack, GiTakeMyMoney } from "react-icons/gi";
import { GrStatusUnknown, GrUpdate } from "react-icons/gr";
import {
  MdCancelPresentation,
  MdChromeReaderMode,
  MdFactCheck,
  MdOutlineLibraryBooks,
  MdOutlineNotificationsNone,
  MdUpdate,
} from "react-icons/md";
import { SiFormstack } from "react-icons/si";
import { TbMessage2, TbReport } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import Loader from "../Components/Loader";
import { dataActionCreators, notificationsActionCreators } from "../services/Actions";
import { GetUserProfile, SignOut } from "../services/Actions/authActions";
import { GetTotalLoanStats } from "../services/Actions/dataAction";
import { GetLoanOfficersMessagesUnread } from "../services/Actions/MessagesAction";
import "./LoansDashboard.css";
import CediSymbol from "../Components/CediSymbol";

function RenderLoanPage({ children, title, page=1 }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { GetNotificationsByLoanOfficers, } =
  bindActionCreators(notificationsActionCreators, dispatch);
  const { GetTotalMonthlyBooked, ResetDataResponse } = bindActionCreators(dataActionCreators, dispatch);
 
  const auth = useSelector((state) => state.auth);
  const data = useSelector((state) => state?.data);
  const message = useSelector((state) => state?.message);
  // const { isLoading } = data;
  const [menuState, setMenuState] = useState(1)

  const { user } = auth;
  const notificationsObj = useSelector((state) => state?.notification);
const { notifications } = notificationsObj;

const { appliedLoans, totalMonthlyLoansBooked, isLoading } = data;

const home = document.getElementById('home');
const fund_deduction = document.getElementById('fund_deduction');
const loan_application = document.getElementById('loan_application');
const booked_loans = document.getElementById('booked_loans');
const initiated_loans = document.getElementById('initiated_loans');
const paid_loans = document.getElementById('paid_loans');
const incomplete_loans = document.getElementById('incomplete_loans');
const updated_loans = document.getElementById('updated_loans');
const rejected_loans = document.getElementById('rejected_loans');
const loan_status = document.getElementById('loan_status');
const loan_calculator = document.getElementById('loan_calculator');
const report = document.getElementById('report');


const pageActiveBgColor = '#797979';
const pageActiveColor = 'white';

// Get current month
const monthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
const date = new Date()
const currentMonth = monthNames[date.getMonth()];
const currentYear = date.getFullYear()

// useEffect(() => {
//   if(page == 1){
//     page  ? home.style.background = pageActiveBgColor : ''
//     page  ? home.style.color = pageActiveColor : ''
//   }else if(page == 2){
//     page  ? fund_deduction.style.background = pageActiveBgColor : ''
//     page  ? fund_deduction.style.color = pageActiveColor : ''
//     // home.style.background = 'inherit'
//   }
// }, [page])

// const UpdatedLoansFromMembers = appliedLoans?.filter((loan) => loan?.status === 'submitted' && loan?.rejected[0]?.resubmit === false && loan?.rejected[0]?.completed === true && loan?.rejected[0]?.editFormChecked === false)

// console.log('UpdatedLoansFromMembers', UpdatedLoansFromMembers)


  console.log("message", message);
  useEffect(() => {
    dispatch(GetTotalLoanStats());
    dispatch(GetUserProfile());
    dispatch(GetLoanOfficersMessagesUnread());
    GetNotificationsByLoanOfficers()
    // GetAppliedLoans(true, 1, 'all', 'submitted');
    GetTotalMonthlyBooked()
  }, []);

  const handleLogout = () => {
    dispatch(SignOut());
    setTimeout(() => {
      window.location.reload(false);
    }, 1000);
  };
  return (
    <div className="LoansDashboard">
      {isLoading && <Loader />}
      <section className="LoanSideBar">
        <div className="menuList">
          <div
            className="menu cursor-pointer"
            onClick={() => history.push("/loans/home")}
            id="home"
            
            style={{
              background: page == 1 ? pageActiveBgColor : '',
              color: page == 1 ? pageActiveColor: ''
            }}
          >
            <AiFillGold className="icon"/>
            <div className="label cursor-pointer">Home</div>
          </div>
          <div
            className="menu cursor-pointer"
            onClick={() => history.push("/loans/fund-deduction")}
            id="fund_deduction"
            style={{
              background: page == 2 ? pageActiveBgColor : '',
              color: page == 2 ? pageActiveColor: ''
            }}
          >
            <FaHands className="icon" />
            <div className="label">Fund Deduction</div>
          </div>
          <div
            className="menu cursor-pointer"
            onClick={() => history.push("/loans/loans-deduction")}
            id="fund_deduction"
            style={{
              background: page == 15 ? pageActiveBgColor : '',
              color: page == 15 ? pageActiveColor: ''
            }}
          >
            <FaHands className="icon" />
            <div className="label">Loans Deduction</div>
          </div>
          <div
            className="menu cursor-pointer"
            onClick={() => history.push("/loans/application")}
            style={{
              background: page == 3 ? pageActiveBgColor : '',
              color: page == 3 ? pageActiveColor: ''
            }}
          >
            <GiMoneyStack className="icon" />
            <div className="label">Loan Applications</div>
          </div>

          <div
            className="menu cursor-pointer"
            onClick={() => history.push("/loans/booked")}
            id="booked_loans"
            style={{
              background: page == 4 ? pageActiveBgColor : '',
              color: page == 4 ? pageActiveColor: ''
            }}
          >
            <BiBookAlt className="icon" />
            <div className="label">Booked Loans </div>
          </div>
          <div
            className="menu cursor-pointer"
            onClick={() => history.push("/loans/authorized")}
            id="booked_loans"
            style={{
              background: page == 14 ? pageActiveBgColor : '',
              color: page == 14 ? pageActiveColor: ''
            }}
          >
            <BiBookAlt className="icon" />
            <div className="label">Authorized Loans </div>
          </div>
          <div
            className="menu cursor-pointer"
            onClick={() => history.push("/loans/initiated")}
            id="initiated_loans"
            style={{
              background: page == 5 ? pageActiveBgColor : '',
              color: page == 5 ? pageActiveColor: ''
            }}
          >
            <MdOutlineLibraryBooks className="icon" />
            <div className="label">Initiated Loans</div>
          </div>
          <div
            className="menu cursor-pointer"
            onClick={() => history.push("/loans/paid")}
            id="paid_loans"
            style={{
              background: page == 6 ? pageActiveBgColor : '',
              color: page == 6 ? pageActiveColor: ''
            }}
          >
            <GiTakeMyMoney
              className="icon"
              style={{ color: "white", fontSize: "1.4rem" }}
            />
            <div className="label">Loans Paid</div>
          </div>
          <div
            className="menu cursor-pointer"
            onClick={() => history.push("/loans/incomplete-loan")}
            id="incomplete_loans"
            style={{
              background: page == 7 ? pageActiveBgColor : '',
              color: page == 7 ? pageActiveColor: ''
            }}
          >
            <SiFormstack className="icon" color="black" />
            <div className="label">Incomplete Loans</div>
          </div>
          <div
            className="menu cursor-pointer"
            onClick={() => history.push("/loans/updated-loan")}
            id="updated_loans"
            style={{
              background: page == 8 ? pageActiveBgColor : '',
              color: page == 8 ? pageActiveColor: ''
            }}
          >
            <MdFactCheck
              color="white"
              style={{ color: "white", fontSize: "1.5rem" }}
              className="icon"
            />
            <div className="label">Updated Loan Forms </div>
            {/* <div className="label">Updated Loan Forms ({UpdatedLoansFromMembers?.length})</div> */}
          </div>
          <div
            className="menu cursor-pointer"
            onClick={() => history.push("/loans/rejected")}
            id="rejected_loans"
            style={{
              background: page == 9 ? pageActiveBgColor : '',
              color: page == 9 ? pageActiveColor: ''
            }}
          >
            <MdCancelPresentation className="icon" />
            <div className="label">Rejected Loans</div>
          </div>
          <div
            className="menu cursor-pointer"
            onClick={() => history.push("/loans/status")}
            id="loan_status"
            style={{
              background: page == 10 ? pageActiveBgColor : '',
              color: page == 10 ? pageActiveColor: ''
            }}
          >
            <MdChromeReaderMode className="icon" />
            <div className="label">Loan Status</div>
          </div>
          <div
            className="menu cursor-pointer"
            onClick={() => history.push("/loans/calculator")}
            id="loan_calculator"
            style={{
              background: page == 11 ? pageActiveBgColor : '',
              color: page == 11 ? pageActiveColor: ''
            }}
          >
            <BsCalculator className="icon" />
            <div className="label">Loan Calculator</div>
          </div>
          <div
            className="menu cursor-pointer"
            onClick={() => history.push("/loans/loan-report")}
            id="report"
            style={{
              background: page == 12 ? pageActiveBgColor : '',
              color: page == 12 ? pageActiveColor: ''
            }}
          >
            <TbReport className="icon" />
            <div className="label">Report</div>
          </div>

          <Divider/>

          <div
            className="menu cursor-pointer"
            onClick={() => handleLogout()}
            id="report"
            // style={{
            //   background: page == 13 ? pageActiveBgColor : '',
            //   color: page == 13 ? pageActiveColor: ''
            // }}
          >
            <FiLogOut className="icon" />
            <div className="label">Logout</div>
          </div>
        </div>
        <div className="profile">
          {/* <div className="flex gap-2 py-2 px-2 cursor-pointer" onClick={() =>history.push('/loans/profile')}>
            <img
              className="p-1 w-10 h-10 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
              src={`${
                user?.profile_image
                  ? user?.profile_image
                  : "/images/dev/avatar1.png"
              }`}
              alt="Bordered avatar"
            />
            <p className="text-sm">{user?.name}</p>
          </div> */}

          <button  className=" py-2">
            <div className="flex items-center justify-center">
              {/* <FiLogOut className="mx-2" size={25} />
              <div style={{fontSize: '1.1rem'}}>Logout</div> */}
            </div>
          </button>
        </div>
      </section>

      <section className="mainPage">
        <div className="topBar">
          <div className="icon_nd_search">
            <div>
              <BsMenuButtonFill />
            </div>
            <div className="menus">
              <li className="cursor-pointer">settings</li>
              <li className="cursor-pointer" onClick={() => history.push("/loans/home")}>Dashboard</li>
            </div>
          </div>
          <div className="prodileInfo">
            <div>
              <IconButton onClick={() => history.push("/loans/messages")}>
                <Badge
                  overlap="rectangular"
                  color="secondary"
                  badgeContent={`${message?.loanMessagesUnread}`}
                  // badgeContent={`0`}
                >
                  {/* <TbMessage2 className="icon" /> */}
                  <MessageSharp style={{ color: "black" }} />
                </Badge>
              </IconButton>
            </div>
            <div className="mr-4">
              <IconButton onClick={() => history.push("/loans/notifications")}>
                <Badge
                  overlap="rectangular"
                  color="secondary"
                  badgeContent={notifications?.length}
                >
                  <NotificationImportant style={{ color: "black" }} />
                </Badge>
              </IconButton>
            </div>
      
      <div className="flex gap-2 justify-center items-center" onClick={() => history.push("/loans/profile")}>
      <img
              
              className="p-1 w-10 h-10 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
              src={`${
                user?.profile_image
                  ? user?.profile_image
                  : "/images/dev/avatar1.png"
              }`}
              alt="Bordered avatar"
            />
      <p className="text-sm">{user?.name.split(' ')[0]}</p>
      </div>
           

          </div>
        </div>

        <div className="px-3 pt-2 pb-6 DashboardContent">
          <h2>{title}</h2>
          <div className="mb-20">
          {children}
          </div>

          <div className="loansDashboardFooter">
          <h2 className="monthlyLoanSumary"> Total Bookings for {currentMonth},  {currentYear} : <strong className="ml-4"> <span  style={{fontWeight: 'bolder', fontFamily:"Tahoma !important"}}>{totalMonthlyLoansBooked.toLocaleString('en-US', { style: 'currency', currency: 'GHS' })}</span> </strong> </h2>
          </div>
        </div>
      </section>
    </div>
  );
}

export default RenderLoanPage;
