import axiosInstance from "../Axios/axios";
import { messageConstants } from "../Constants/messageConstant";

// export const GetConversations = (user_id) => {
//     return async (dispatch) => {
//       try {
//         console.log("user_id", user_id);
//         dispatch({
//           type: messageConstants.LOADING,
//           isLoading: true,
//         });
//         const response = await axiosInstance.get(
//           `/messenger/conversation/${user_id}`
//         );

//         if (response) {

//           dispatch({
//             type: messageConstants.GET_CONVERSATIONS,
//             payload: { conversations: response.data },
//           });
//           dispatch({
//             type: messageConstants.LOADING,
//             isLoading: false,
//           });
//         }
//       } catch (e) {
//         dispatch({
//           type: messageConstants.LOADING,
//           isLoading: false,
//         });
//       }
//     };
//   };

export const addMessageBySystemUser = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: messageConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.post(
        "/messages/add-by-system-user",
        data
      );
      if (response) {
        dispatch({
          type: messageConstants.RESPONSE,
          response: {
            state: "SUCCESS",
            message: "Message sent",
          },
        });
        dispatch({
          type: messageConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: messageConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: messageConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const sendMessageToMembersBySystemUser = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: messageConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.post(
        "/messages/admin-message-to-member",
        data
      );
      if (response) {
        dispatch({
          type: messageConstants.RESPONSE,
          response: {
            state: "SUCCESS",
            message: "Message sent",
          },
        });
        dispatch({
          type: messageConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: messageConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: messageConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const EditMessageBySystemUser = (data,message_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: messageConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.put(
        `/messages/edit-by-system-user/${message_id}`,
        data
      );
      if (response) {
        dispatch({
          type: messageConstants.RESPONSE,
          response: {
            state: "SUCCESS",
            message: "Message edited",
          },
        });
        dispatch({
          type: messageConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: messageConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: messageConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const DeleteMessageBySystemUser = (data,message_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: messageConstants.LOADING,
        isLoading: true,
      });
      const response = await axiosInstance.delete(
        `/messages/delete-by-system-user/${message_id}`,
        data
      );
      if (response) {
        dispatch({
          type: messageConstants.RESPONSE,
          response: {
            state: "SUCCESS",
            message: "Message deleted",
          },
        });
        dispatch({
          type: messageConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: messageConstants.RESPONSE,
        response: {
          state: "ERROR",
          message: e?.response?.data.message ?? "Connection failed. Try again later",
        },
      });
      dispatch({
        type: messageConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const GetMessagesByDepartment = (department) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: messageConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.get(
        `/messages/department/${department}`
      );
      if (response) {
        dispatch({
          type: messageConstants.GET_MESSAGES,
          payload: { messages: response.data },
        });
        dispatch({
          type: messageConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: messageConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetLoanOfficersMessagesUnread = (department) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: messageConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.get(
        `/messages/loan-officers-total`
      );
      if (response) {
        dispatch({
          type: messageConstants.LOAN_OFFICERS_UNREAD,
          payload: { loanMessagesUnread: response.data },
        });
        dispatch({
          type: messageConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: messageConstants.LOADING,
        isLoading: false,
      });
    }
  };
};
export const GetMartOfficersMessagesUnread = (department) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: messageConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.get(
        `/messages/mart-officers-total`
      );
      if (response) {
        dispatch({
          type: messageConstants.MART_OFFICERS_UNREAD,
          payload: { martMessagesUnread: response.data },
        });
        dispatch({
          type: messageConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: messageConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

export const getMessageDetailed = (chat_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: messageConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.get(
        `/messages/member-message-detailed/${chat_id}`
      );
      if (response) {
        dispatch({
          type: messageConstants.GET_MESSAGE_DETAILED,
          payload: { messageDetailed: response.data },
        });
        dispatch({
          type: messageConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: messageConstants.LOADING,
        isLoading: false,
      });
    }
  };
};


export const MarkMessageAsRead = (message_id, chat_id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: messageConstants.LOADING,
        isLoading: true,
      });

      const response = await axiosInstance.put(
        `/messages/mark-system-user-as-read/${message_id}?chat_id=${chat_id}`
      );
      if (response) {
        dispatch({
          type: messageConstants.RESPONSE,
          response: {
            state: "SUCCESS",
            message: "marked as read",
          },
        });
        dispatch({
          type: messageConstants.LOADING,
          isLoading: false,
        });
      }
    } catch (e) {
      dispatch({
        type: messageConstants.LOADING,
        isLoading: false,
      });
    }
  };
};

//   export const GetUsersForChat = () => {
//     return async (dispatch) => {
//       try {
//         dispatch({
//           type: messageConstants.LOADING,
//           isLoading: true,
//         });

//         const response = await axiosInstance.get(
//           `/messenger/users`
//         );
//         if (response) {
//           dispatch({
//             type: messageConstants.GET_USERS_FOR_CHAT,
//             payload: { usersForChat: response.data },
//           });
//           dispatch({
//             type: messageConstants.LOADING,
//             isLoading: false,
//           });
//         }
//       } catch (e) {
//         dispatch({
//           type: messageConstants.LOADING,
//           isLoading: false,
//         });
//       }
//     };
//   };

// export const addMessages = (data) => {
//     return async (dispatch) => {
//       try {
//         dispatch({
//           type: messageConstants.LOADING,
//           isLoading: true,
//         });
//         const response = await axiosInstance.post("/messenger/messages", data);
//         if (response) {
//           dispatch({
//             type: messageConstants.GET_MESSAGES,
//             payload: response.data,
//           });
//           dispatch({
//             type: messageConstants.LOADING,
//             isLoading: false,
//           });
//         }
//       } catch (e) {
//         dispatch({
//           type: messageConstants.LOADING,
//           isLoading: false,
//         });
//       }
//     };
//   };

export const ResetMessagesResponse = () => {
  return async (dispatch) => {
    dispatch({
      type: messageConstants.RESPONSE,
      response: {
        state: null,
        message: "",
      },
    });
  };
};
