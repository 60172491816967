/* eslint-disable  */

import {
  FormControlLabel, Switch
} from "@material-ui/core";
import { debounce } from "lodash";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { format } from "timeago.js";
import Loader from "../../Components/Loader";
import NotFoundComponent from "../../Components/NotFoundComponent";
import { dataActionCreators } from "../../services/Actions";
import RenderLoanPage from "../RenderLoanPage";
import { ToastContainer, toast } from "react-toastify";

function LoanStatus() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { GetAppliedLoans, ResetDataResponse } = bindActionCreators(dataActionCreators, dispatch);
  const [checkSouthLoan, setCheckSouthLoan] = useState(false);
  const [checkNorthLoan, setCheckNorthLoan] = useState(false);
  const [search, setSearch] = useState(null);
  const [limit, setLimit] = useState(30);
  const data = useSelector((state) => state?.data);
  const status = "all"


  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = limit;
  const [currentPage, setCurrentPage] = useState(0);

  const { appliedLoans, paginate, isLoading, response } = data;


  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;

    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(appliedLoans?.slice(itemOffset, endOffset));
    setPageCount(Number(paginate?.total));
  }, [itemOffset, itemsPerPage, appliedLoans]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    console.log("event", event);
    GetAppliedLoans(true, event.selected + 1, limit, status);

    console.log("currentPage", currentPage);

    const newOffset = (event.selected * itemsPerPage) % paginate?.total;

    setItemOffset(newOffset);
  };

  const handleSouthChange = (e) => {
    console.log('e.target.checked', e.target.checked)
    setCheckSouthLoan(e.target.checked);
  };
  const handleNorthChange = (e) => {
    setCheckNorthLoan(e.target.checked);
  };

  

  const onSearchTextChange = debounce((e) => {
    if (e?.target?.value) {
      setSearch(e?.target?.value ?? null);
      GetAppliedLoans(
        true,
        1,
        limit,
        status,
        `${e?.target?.value ? "&search=" + e?.target?.value : ""}`
      );
      // GetAllFunds(true, 1, limit, `${e?.target?.value ? "&search=" + e?.target?.value : ""}`)
    } else {
      setSearch(null);
      GetAppliedLoans(true, 1, limit, status);
    }
  }, 800);
  const Northern = checkNorthLoan && "Northern";
  const Southern = checkSouthLoan && "Southern";
  let sectorSeleted = "";
  if (checkNorthLoan) {
    sectorSeleted = "North"
  } else if (checkSouthLoan) {
    sectorSeleted = "South"
  } else {
    sectorSeleted = "No"
  }



  useEffect(() => {
    GetAppliedLoans(true, 1, limit, status);
    setCheckNorthLoan(false);
    setCheckSouthLoan(false);
  }, []);

  useEffect(()=> {
    GetAppliedLoans(true, 1, limit, status);
  },[limit])

  useEffect(() => {
    if (Northern && Southern) {
      GetAppliedLoans(true, 1, limit, status);
    } else if (Northern) {
      GetAppliedLoans(
        true,
        1,
        limit,
        status,
        `${Northern ? "&filter=" + "Northern" : ""}`
      );
    } else if (Southern) {
      GetAppliedLoans(
        true,
        1,
        limit,
        status,
        `${Southern ? "&filter=" + "Southern" : ""}`
      );
    } else {
      GetAppliedLoans(true, 1, limit, status);
    }

    // GetAppliedLoans("booked");
  }, [Southern, Northern]);

  useEffect(() => {
    GetAppliedLoans(
      true,
      1,
      limit,
      status,
      `${Northern ? "&filter=" + "Northern" : ""}`
    );
    // GetAppliedLoans("booked");
  }, [Northern]);

  useEffect(() => {
    if (response?.state === "SUCCESS") {
      toast.success(response?.message);
      setTimeout(() => {
        ResetDataResponse();
      
      }, 1500);
    } else if (response?.state === "ERROR") {
      toast.error(response?.message);
      setTimeout(() => {
        ResetDataResponse();
       
      }, 1500);
    }
  }, [response?.state]);

  return (
    <div className="LoanApplication">
      <RenderLoanPage title={""} page={10}>
        {isLoading && <Loader />}
        <div className="row">
          <div className="col-md-7">
            <label htmlFor="search">Search Query:</label>
            <input
              type={"search"}
              id="search"
              placeholder="search by staff id, name"
              className="form-control"
              onChange={onSearchTextChange}
            />
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-2">
            <label htmlFor="search">List to show:</label>
            <select
              className="form-control"
              onChange={(e) => setLimit(e.target.value)}
            >
              {/* <option value={5}>5</option> */}
              {/* <option value={15}>15</option> */}
              {/* <option value={25}>25</option> */}
              <option value={30}>30</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
              <option value={400}>400</option>
              <option value={500}>500</option>
            </select>
          </div>
        </div>

        {/* {appliedLoans.length > 0 ? ( */}
        <section className="bg-white mt-4">
          <div className="container mx-auto px-4 sm:px-8">
            <div className="py-8">
              <div className="flex items-center justify-between">
                <h2 className="text-2xl font-semibold leading-tight">
                  Loan Status
                </h2>
                <div className="flex items-center justify-between">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checkSouthLoan}
                        onChange={handleSouthChange}
                      />
                    }
                    label="South Loans"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checkNorthLoan}
                        onChange={handleNorthChange}
                      />
                    }
                    label="North Loans"
                  />
                </div>
              </div>
              {/* here */}

              <div className="-mx-1 sm:-mx-8 px-4 sm:px-8 py-0 overflow-x-auto">
                <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                  <table className="min-w-full leading-normal">
                    <thead>
                      <tr>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Name
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Loan Amount
                        </th>
                        {/* <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            Mandate Number
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            OTP
                          </th> */}
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Date
                        </th>
                        <th className="px-3 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Status
                        </th>
                        <th className="px-3 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Sector
                        </th>
                        {/* <th className="px-1 py-3 border-b-2 border-gray-200 bg-gray-100"></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {appliedLoans?.length > 0 ? (
                        <>
                          {appliedLoans.map((loan, index) => {
                            console.log(loan.status);
                            let color = 'bg-red-200'
                            let opacity = 'opacity-50'

                            if (loan?.status === 'submitted') {
                              color = 'bg-blue-800'
                            } else if (loan?.status === "initiated") {
                              color = 'bg-yellow-200'
                            } else if (loan?.status === "paid") {
                              color = 'bg-green-600'
                              opacity = 'opacity-1'
                            } else if (loan?.status === "rejected") {
                              color = 'bg-red-700'
                            } else if (loan?.status === "booked") {
                              color = 'bg-green-200'
                            }
                            return (
                              <tr key={loan?._id}>
                                <td className="px-1 py-3 border-b border-gray-200 bg-white text-sm">
                                  <div className="flex">
                                    <div className="flex-shrink-0 w-10 h-10">
                                      {console.log('loan?.user?.profile_image ', loan?.user?.profile_image )}
                                      {loan?.user?.profile_image ? (
                                        <img
                                          className="w-full h-full rounded-full"
                                          src={loan?.user?.profile_image}
                                          alt=""
                                        />
                                      ) : (
                                        <>
                                          {loan?.user?.name?.split(" ")[0] ===
                                            "Mr." ? (
                                            <img
                                              className="w-full h-full rounded-full"
                                              src="/images/dev/avarta2.jpeg"
                                              alt=""
                                            />
                                          ) : (
                                            <img
                                              className="w-full h-full rounded-full"
                                              src="/images/dev/woman-avarta.jpeg"
                                              alt=""
                                            />
                                          )}
                                        </>
                                      )}
                                    </div>
                                    <div className="ml-3">
                                      <p className="text-gray-900 whitespace-no-wrap">
                                        {loan?.user?.name}
                                      </p>
                                      <p className="text-bold text-gray-600 whitespace-no-wrap">
                                        {loan?.staff_id}
                                      </p>
                                    </div>
                                  </div>
                                </td>

                                <td className="px-5 py-1 border-b border-gray-200 bg-white text-sm">
                                  <p className="text-gray-900 whitespace-no-wrap">
                                    &#8373;{loan?.loanAmount}
                                  </p>
                                  <p className="text-gray-600 whitespace-no-wrap">
                                    MD : {loan?.monthlyDeduction}
                                  </p>
                                </td>
                                {/* <td className="px-5 py-1 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  {loan?.mandateNumber}
                                </p>
                              
                              </td>
                              <td className="px-5 py-1 border-b border-gray-200 bg-white text-sm">
                                <p className="text-gray-900 whitespace-no-wrap">
                                  {loan?.otpNumber}
                                </p>
                               
                              </td> */}

                                <td className="px-5 py-1 border-b border-gray-200 bg-white text-sm">
                                  <p className="text-gray-900 whitespace-no-wrap">
                                    {moment(loan?.created_at).format(
                                      "MMMM Do YYYY"
                                    )}
                                  </p>
                                  <p className="text-gray-600 whitespace-no-wrap">
                                    {/* {'time ago here'} */}
                                    {format(loan?.created_at)}
                                  </p>
                                </td>
                                <td className="px-2 py-1 border-b border-gray-200 bg-white text-sm">
                                  <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                    <span
                                      aria-hidden
                                      className={`absolute inset-0 ${color} ${opacity} rounded-full`}
                                    ></span>
                                    <span className="relative">
                                      {loan?.status}
                                    </span>
                                  </span>
                                </td>
                                <td className="px-3 py-1 border-b border-gray-200 bg-white text-sm">
                                  <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                    <span
                                      aria-hidden
                                      className={`absolute inset-0 ${loan?.sector === "Northern"
                                          ? "bg-red-300"
                                          : "bg-blue-300"
                                        } opacity-50 rounded-full`}
                                    ></span>
                                    <span className="relative">
                                      {loan?.sector}
                                    </span>
                                  </span>
                                </td>

                              </tr>
                            );
                          })}

                        </>
                      ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <NotFoundComponent
                            title={`${sectorSeleted} loans status at the monent`}
                          />
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>

          
                {appliedLoans.length > 0 && (
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        containerClassName="pagination"
                        pageLinkClassName="page-num"
                        previousLinkClassName="page-num"
                        nextLinkClassName="page-num"
                        activeLinkClassName="active"
                      />
                    )}
              </div>
            </div>
          </div>
        </section>
        <ToastContainer autoClose={3000}/>
      </RenderLoanPage>
    </div>
  );
}

export default LoanStatus;
