/* eslint-disable  */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import Loader from "../../Components/Loader";
import { RolesContants } from "../../contants";
import { dataActionCreators } from "../../services/Actions";
import { GetUserProfile } from "../../services/Actions/authActions";
import RenderLoanPage from "../RenderLoanPage";
import { FormControlLabel, Switch } from "@material-ui/core";
import "./ReportLoans.css";
import { FaDownload, FaRegFilePdf   } from "react-icons/fa";
import { GetLoanReport } from "../../services/Actions/dataAction";

function ReportLoans() {
  const dispatch = useDispatch();
  const { ResetDataResponse } = bindActionCreators(dataActionCreators, dispatch);
  const [generateAllReport, setGenerateAllReport] = useState(false);
  const [oneDomainReport, setOneDomainReport] = useState(false);

  const [formData, setFormData] = useState({
      category: "",
      fromDate: "",
      toDate: "",

  });

  const data = useSelector((state) => state?.data);
  const user = useSelector((state) => state?.auth);
  const { isLoading, response } = data;

  useEffect(() => {
      dispatch(GetUserProfile());
  }, [dispatch]);

  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
  };

  const handleChangeGenerateReport = () => {
      setGenerateAllReport(!generateAllReport)
  }
  const handleChangeOneDomain = () => {
      setOneDomainReport(!oneDomainReport)
  }

  const handleGenerateAll = () => {
    if (user?.role?.includes(RolesContants.ADMIN, RolesContants.LOAN_OFFICER_HEAD)) {
        if (generateAllReport == false && oneDomainReport == false) {
            if(!formData.fromDate || !formData.toDate){
                toast.error("Choose the range. This is required");
                return
            }
            dispatch(GetLoanReport({
                ...formData, 
                is_all: generateAllReport,
                isOneDomain: oneDomainReport
            }));
        }else if(generateAllReport == true && oneDomainReport == true){
            dispatch(GetLoanReport({
                ...formData, 
                is_all: generateAllReport,
                isOneDomain: oneDomainReport
            }));
        } else if(oneDomainReport == true){
            if(!formData.fromDate || !formData.toDate){
                toast.error("Choose the range. This is required");
                return
            }
            dispatch(GetLoanReport({
                ...formData, 
                is_all: generateAllReport,
                isOneDomain: oneDomainReport
            }));
        }else {
            dispatch(GetLoanReport({
                ...formData, 
                is_all: generateAllReport,
                isOneDomain: oneDomainReport
            }));
        }

   
        console.log(formData)
    } else {
        toast.error("You are not authorized to generate report on loans");
    }

  
  };

  useEffect(() => {
      if (response?.state === "SUCCESS") {
          toast.success(response?.message);
          setTimeout(() => {
              ResetDataResponse();
          }, 1500);
      } else if (response?.state === "ERROR") {
          toast.error(response?.message);
          setTimeout(() => {
              ResetDataResponse();
          }, 1500);
      }
  }, [response?.state, ResetDataResponse]);

  return (
    <RenderLoanPage page={12}>
      {isLoading && <Loader />}
      <div className="container-xl shadow w-100 p-10 bg-white rounded-lg mt-4">
      <h2 className="text-center font-bold text-2xl pb-5 d-flex align-items-center justify-content-center">
  <FaRegFilePdf  className="me-2" /> Generate Report
</h2>

                    <div className="row d-flex justify-content-center">

                    <div className="col-md-3 mb-3">
                            <label htmlFor="category" className="form-label">Status:</label>
                            <select
                                className="form-select"
                                id="category"
                                name="category"
                                value={formData.category}
                                onChange={handleChange}
                            >
                                <option value="" disabled>Select Status</option>
                                {/* <option value="all">Total Loans Report</option> */}
                                <option value="submitted">Submitted Report</option>
                                <option value="booked">Booked Report</option>
                                <option value="initiated">Initiated Report</option>
                                <option value="paid">Paid Report</option>
                                <option value="authorized">Authourized Report</option>
                                <option value="rejected">Rejected Report</option>
                            </select>
                        </div>
                        <div className="col-md-1 mb-3">
                            <div htmlFor="" className="form-label">Select all:</div>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={generateAllReport}
                                        onChange={handleChangeGenerateReport}
                                    />
                                }

                            />
                        </div>

                        <div className="col-md-2 mb-3">
                            <div htmlFor="" className="form-label">One Domain:</div>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={oneDomainReport}
                                        onChange={handleChangeOneDomain}
                                    />
                                }

                            />
                        </div>

                        <div className="col-md-2 mb-3">
                            <label htmlFor="fromDate" className="form-label">From:</label>
                            <input
                                type="date"
                                className="form-control"
                                id="fromDate"
                                name="fromDate"
                                readOnly={generateAllReport ? true : false}
                                value={formData.fromDate}
                                onChange={handleChange}
                            />
                        </div>


                        <div className="col-md-2 mb-3">
                            <label htmlFor="toDate" className="form-label">To:</label>
                            <input
                                type="date"
                                className="form-control"
                                id="toDate"
                                readOnly={generateAllReport ? true : false}
                                name="toDate"
                                value={formData.toDate}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="row pt-5 gt-all">
                        <div className="col-md-12 d-flex justify-content-center">
                            <button className="btn btn-success me-3 gt-btn" onClick={handleGenerateAll}>
                                <FaDownload className="me-2" /> Generate
                            </button>
                            {/* <button className="btn btn-success gt-btn">
                                <FaDownload className="me-2" /> Generate All
                            </button> */}
                        </div>
                    </div>
                    </div>
                    </div>

      <ToastContainer autoClose={3000}/>
    </RenderLoanPage>
  );
}

export default ReportLoans;
