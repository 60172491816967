import { notificationConstants } from "../Constants/notificationConstant";


const initiaState = {
  isLoading: false,
  notifications: [],
  mart_notifications: [],
  response: {
    state: null,
    message: "",
  },
};

const notificationReducer = (state = initiaState, action) => {
  // console.log("action", action);
  switch (action.type) {
    case notificationConstants.LOADING:
      return {
        ...state,
        isLoading: action?.isLoading,
      };

    case notificationConstants.GET_NOTIFICATIONS_BY_LOAN_OFFICER:
      return {
        ...state,
        notifications: action?.payLoad?.notifications,
      };
    case notificationConstants.GET_NOTIFICATIONS_BY_MART_OFFICER:
      return {
        ...state,
        mart_notifications: action?.payLoad?.notifications,
      };
    case notificationConstants.RESPONSE_STATE:
      return {
        ...state,
        response: {
          state: action?.response?.state,
          message: action?.response?.message,
        },
      };

    default:
      return state;
  }
};

export default notificationReducer;
