/* eslint-disable  */
import React, { useEffect, useRef, useState } from "react";
import { BiSupport } from "react-icons/bi";
import { BsCalendar2DateFill } from "react-icons/bs";
import { GrStatusInfo } from "react-icons/gr";
import { MdOutlineUpdate } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import Loader from "../../Components/Loader";
import {
  authActionCreators,
  messageActionCreators,
} from "../../services/Actions";
import RenderLoanPage from "../RenderLoanPage";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import PromptModal from "../../AdminModel/Components/PromptModal";

function LoansMessagesDetailed() {
  const history = useHistory();
  const dispatch = useDispatch();
  const chat_id = history.location?.state?.chat_id;
  const { GetUserProfile } = bindActionCreators(authActionCreators, dispatch);
  const { getMessageDetailed, addMessageBySystemUser, EditMessageBySystemUser, DeleteMessageBySystemUser, ResetMessagesResponse } =
    bindActionCreators(messageActionCreators, dispatch);
  const [formData, setFormData] = useState({ message: "" });
  const scrollRef = useRef();
  const handleChange = (e) => {
    setFormData({ ...formData, [e?.target?.name]: e?.target.value });
  };
  const auth = useSelector((state) => state?.auth);
  const messages = useSelector((state) => state?.message);
  const [openEditMessageFormModal, setOpenEditMessageFormModal]= useState(false);
  const [editedMessage, setEditedMessage]= useState('');
  const [messageId, setMessageId]= useState(null);

  let timestamp = new Date().getTime();


  const { message, isLoading, messageDetailed, response } = messages;

  console.log("messageDetailed", messageDetailed);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log('first', first)
    if (formData.message === "") {
      toast.error("message box is empty");
    } else {
      const payload = {
        ...formData,
        system_user: auth?.user?._id,
        // user_id: auth?.user?._id,
        system_user_name: auth?.user?.name,
        system_user_profile: auth?.user?.profile_image,
        reference: messageDetailed?.reference,
      };
      addMessageBySystemUser(payload);
      setFormData({ ...formData, message: "" });
    }
  };

  const handleFormSaveEdited = (message_id) => {
    const payload = {
      message: editedMessage,
      system_user: auth?.user?._id,
      // user_id: auth?.user?._id,
      system_user_name: auth?.user?.name,
      system_user_profile: auth?.user?.profile_image,
      reference: messageDetailed?.reference,
    };
    console.log('messageId', messageId)
    EditMessageBySystemUser(payload, messageId);
    setOpenEditMessageFormModal(false)
  }
  const handleDeleteSingleMessage = (message_id) => {
    const payload = {
      message: editedMessage,
      system_user: auth?.user?._id,
      // user_id: auth?.user?._id,
      system_user_name: auth?.user?.name,
      system_user_profile: auth?.user?.profile_image,
      reference: messageDetailed?.reference,
    };
    console.log('messageId', messageId)
    DeleteMessageBySystemUser(payload, messageId);
  }

  useEffect(() => {
    GetUserProfile();
  }, []);
  useEffect(() => {
    getMessageDetailed(chat_id);
  }, [chat_id]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messageDetailed?.chat]);

  useEffect(() => {
    if (response.state === "SUCCESS") {
      getMessageDetailed(chat_id);
      // toast.success(response?.message ?? "Notifications loaded");
      setTimeout(() => {
        ResetMessagesResponse();
      }, 1500);
    } else if (response.state === "ERROR") {
      toast.error(response?.message ?? "unable to get notification");
      setTimeout(() => {
        ResetMessagesResponse();
      }, 1500);
    }
  }, [response.state, response?.message]);
  return (
    <div className="MessageDetailed">
      <RenderLoanPage>
        {isLoading && <Loader />}
        <span className="backbtn" onClick={() => history.goBack()}>back</span>
        <div className="messageShowCase">
          <h2>{messageDetailed?.subject}</h2>
          <div className="itemsConainer">
            <div className="item">
              <div>
                <GrStatusInfo className="icon" />
              </div>
              <div>Member Staff ID</div>
              <div><strong>{messageDetailed?.user?.staff_id}</strong></div>
              {/* <div>{messageDetailed?.isOpen ? "Open" : "Closed"}</div> */}
            </div>
            <div className="item">
              <div>
                <BiSupport className="icon" />
              </div>
              <div>Department</div>
              <div>{messageDetailed?.system_user}</div>
            </div>
            <div className="item">
              <div>
                <MdOutlineUpdate className="icon" />
              </div>
              <div>Last Updated</div>
              <div>
                {moment(messageDetailed?.updated_at).format("MMM Do YY")}
              </div>
            </div>
            <div className="item">
              <div>
                <BsCalendar2DateFill className="icon" />
              </div>
              <div>Submitted on</div>
              <div>
                {moment(messageDetailed?.created_at).format("MMM Do YY")}
              </div>
            </div>
          </div>
        </div>

        <div className="messageBox">
          <h2>Message Area</h2>
          <section className="chat">
            <div className="messages-chat">
              {messageDetailed?.chat?.map((ch, index) => {
                return ch?.user_type === "system_user" ? (
                  <div ref={scrollRef} key={index}>
                    <div className="message text-only ">
                      <div className="response">
                        <p className="text"> {ch?.message}</p>
                        <p className="response-time  mt-2 mb-2">
                        <span className="bg-green-500 px-2 py-1 text-white rounded-lg font-bold cursor-pointer mr-2 mb-2"  onClick={()=> {setOpenEditMessageFormModal(true); setEditedMessage(ch?.message); setMessageId(ch?._id)}}>Edit</span>
                        {/* <span className="bg-red-500 px-2 font-bold cursor-pointer mr-2"  onClick={()=> {handleDeleteSingleMessage(ch?._id);}}>Del</span> */}
                          {" "}
                          {moment(ch?.data).format("MMM Do YY")}
                        </p>
                      </div>
                      <div
                        className="photo"
                        style={{
                          backgroundImage: `url(${auth?.user?.profile_image+'?'+timestamp})`,
                        }}
                      >
                        <img  className="rounded-full" src={auth?.user?.profile_image+'?'+timestamp} alt="profile"/>
                        {/* <div className="online"></div> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div ref={scrollRef} key={index}>
                    <div className="message">
                      <div
                        className="photo"
                        style={{
                          backgroundImage: `url(${messageDetailed?.user?.profile_image+'?'+timestamp})`,
                        }}
                      >
                        <img  className="rounded-full" src={messageDetailed?.user?.profile_image+'?'+timestamp} alt="profile"/>
                        {/* <div className="online"></div> */}
                      </div>
                      <p className="text"> {ch?.message}</p>
                    </div>
                    <p className="time">
                  
                      {" "}
                      {moment(ch?.data).format("MMM Do YY")}
                    </p>
                  </div>
                );
              })}
            </div>
            <form>
              <div className="footer-chat">
                <input
                  type="text"
                  onChange={handleChange}
                  name="message"
                  value={formData.message}
                  className="write-message"
                  placeholder="Type your message here"
                ></input>
                <button type="submit" className="button" onClick={handleSubmit}>
                  Send
                </button>
              </div>
            </form>
          </section>
        </div>
      </RenderLoanPage>

      {openEditMessageFormModal && (
          <PromptModal
            closeModal={setOpenEditMessageFormModal}
            width={"40%"}
            title="Edit Message"
            height={'30vh'}
          >
            <div className="relative  ">
              <div className="p-6 text-center">
            
              
              {/* Start of form */}
              <div className="mb-4">
                  <label
                    htmlFor="message"
                    className="block mb-2 text-sm font-medium"
                  >
                    Edited message
                  </label>
                  <textarea
                    id="message"
                    rows="4"
                    defaultValue={editedMessage}
                    onChange={(e) => setEditedMessage(e.target.value)}
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write the reason for rejecting loan here. The member will see this..."
                  ></textarea>
                </div>
              {/* End of form */}
               
                <button
                  data-modal-toggle="popup-modal"
                  type="button"
                  onClick={() => {
                    handleFormSaveEdited()
                    // if (rejectMessage === "") {
                    //   setError("Please add message first");

                    //   setTimeout(() => {
                    //     setError("");
                    //   }, 5000);
                    // } else {
                    //   //mark as rejected
                    //   const payload = {
                    //     status: true,
                    //     message: rejectMessage,
                    //     resubmit: false,
                    //     editFormChecked: checkedFormEdit,
                    //     completed: false,
                    //   };

                    //   MarkedAsRejectedAppliedLoans(loanDetailed?._id, payload);
                    //   setRejectedMessage("");
                    //   setCheckedFormEdit(false);
                    //   setPromptOpenRejectModal(false);
                    // }
                  }}
                  className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                >
                  Save Edited
                </button>
                <button
                  data-modal-toggle="popup-modal"
                  type="button"
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  onClick={()  =>setOpenEditMessageFormModal(false)}
                >
                  No, cancel
                </button>
              </div>
            </div>
          </PromptModal>
        )}
      <ToastContainer autoClose={3000} />
    </div>
  );
}

export default LoansMessagesDetailed;
